<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="Docs">
			<b-col cols="12" xl="12">
				<transition name="slide">
					<div>
						<b-row v-if="showInputFile">
							<b-col sm="12">
								<b-form-group class="row" :label="FormMSG(16, 'Add here:')" :label-for="buttonId" :label-cols="3">
									<b-form-file
										v-model="pdfFile"
										:browse-text="FormMSG(113, 'Browse')"
										name="test"
										:id="buttonId"
										:accept="acceptFiles"
										ref="pdfFileId"
										@change="submitDocFile"
										placeholder="Choose a file or drop it here..."
										drop-placeholder="Drop file here..."
										:disabled="isSingleFile && Docs.length === 1"
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12" xl="12">
								<div v-if="Docs.length > 0">
									<CardListBuilder custom-class="hide-on-desktop" :items="Docs" :fields="docMobileFields">
										<template slot="actions" slot-scope="data">
											<b-button
												size="sm"
												variant="primary"
												class="mr-2"
												v-if="data.item.fileName.length > 0"
												@click="viewDocument(data.item)"
											>
												<i class="icon-eye" />
											</b-button>
											<b-button size="sm" variant="danger" @click="removeDocument(data.item.xid)">
												<i class="icon-trash" />
											</b-button>
										</template>
									</CardListBuilder>
									<b-table
										class="hide-on-tablet"
										:hover="hover"
										responsive="sm"
										ref="myTable"
										selectable
										:selectedVariant="selectedColor"
										:select-mode="selectMode"
										:items="Docs"
										style="text-align: left"
										sticky-header="400px"
										:fields="docfields"
										:current-page="currentPage"
										:per-page="perPage"
										bordered
										small
										:head-variant="hv"
									>
										<template v-slot:cell(attachToOffer)="data">
											<b-form-checkbox v-model="data.item.attachToOffer" size="lg" @change="handleAttachToOffer($event, data.item.xid)" />
										</template>
										<template v-slot:cell(attachToOrder)="data">
											<b-form-checkbox v-model="data.item.attachToOrder" size="lg" @change="handleAttachToOrder($event, data.item.xid)" />
										</template>
										<template v-slot:cell(attachToInvoice)="data">
											<b-form-checkbox
												v-model="data.item.attachToInvoice"
												size="lg"
												@change="handleAttachToInvoice($event, data.item.xid)"
											/>
										</template>
										<template v-slot:cell(actions)="data">
											<b-button
												variant="primary"
												class="mr-2"
												size="sm"
												v-if="data.item.fileName.length > 0"
												@click="viewDocument(data.item)"
											>
												<i class="icon-eye" />
											</b-button>
											<b-button variant="danger" size="sm" @click="removeDocument(data.item.xid)">
												<i class="icon-trash" />
											</b-button>
										</template>
									</b-table>
								</div>
							</b-col>
						</b-row>
						<loading is-full-page :active.sync="isLoading" color="#00A09C" />
					</div>
				</transition>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import { getFileExtension } from '@/shared/helpers';
import { store } from '@/store';
import { getDocuments, getAttachStatus, updateImageChild } from '@/cruds/image-child.crud';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { isNil, makeID } from '~utils';
import moment from 'moment';
import { isFileSupported } from '@/utils/isFileSupported';

const axios = require('axios').create();

export default {
	name: 'GreenFilmDocs',
	components: {
		Loading
	},
	mixins: [languageMessages, globalMixin],
	props: {
		GFId: {
			type: [String, Number],
			default: '6'
		},
		GFType: {
			type: String,
			default: 'greenfilm'
		},
		GFSubType: {
			type: String,
			default: 'PR2'
		},
		hover: {
			type: Boolean,
			default: true
		},
		showInputFile: {
			type: Boolean,
			required: false,
			default: true
		},
		uploadUrl: {
			type: String,
			required: false,
			default: 'upload'
		},
		acceptFiles: {
			type: String,
			required: false,
			default: '.jpg,.png,.gif,.pdf,.xlsx,.xls,.doc,.docx,.txt,.docs,.odt,.ods,.ppt,.pptx'
		},
		isSingleFile: {
			type: Boolean,
			required: false,
			default: false
		},
		removeUrl: {
			type: String,
			required: false,
			default: 'removedocument'
		},
		formFilename: {
			type: String,
			required: false,
			default: 'uploadimage'
		},
		attachmentToOfferOrderInvoiceOnly: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	created() {
		this.$previewerEventListener('green_film_doc_previewer', (testeeeeeuh) => {
			console.log({ testeeeeeuh });
		});
	},

	mounted() {
		this.$nextTick(async () => {
			await this.reloadData();
		});
	},

	data: () => {
		const buttonId = makeID(4);
		return {
			hv: 'dark',
			buttonId,
			erreur: {},
			Docs: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			isLoading: false,
			pdfFile: null,
			stopPropagation: '',
			selectMode: 'single',
			selectedColor: 'primary'
		};
	},

	computed: {
		optionsForDocType() {
			return this.FormMenu(1011);
		},
		baseFields() {
			return [
				{
					key: 'fileName',
					label: this.FormMSG(52, 'File name'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(53, 'Publisher'),
					sortable: true
				},
				{
					key: 'publishedOn',
					label: this.FormMSG(54, 'Date'),
					sortable: true,
					formatter: (value) => {
						return value !== '0001-01-01T00:00:00Z' ? new moment(value).utc().format('DD/MM/YYYY HH:mm') : '';
					},
					class: 'text-center'
				},
				{
					key: 'actions',
					label: this.FormMSG(75, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		docfields() {
			return this.attachmentToOfferOrderInvoiceOnly ? this.docAttachFields : this.baseFields;
		},
		docMobileFields() {
			const keys = ['actions'];
			const baseFields = this.attachmentToOfferOrderInvoiceOnly ? this.docAttachFields : this.baseFields;
			return baseFields.filter((t) => !keys.includes(t.key));
		},
		docAttachFields() {
			return [
				{
					key: 'fileName',
					label: this.FormMSG(52, 'File name'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(53, 'Publisher'),
					sortable: true
				},
				{
					key: 'publishedOn',
					label: this.FormMSG(54, 'Date'),
					sortable: true,
					formatter: (value) => {
						return value !== '0001-01-01T00:00:00Z' ? new moment(value).utc().format('DD/MM/YYYY HH:mm') : '';
					},
					class: 'text-center'
				},
				{
					key: 'attachToOffer',
					label: this.FormMSG(5, 'Attach to Offer'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'attachToOrder',
					label: this.FormMSG(4, 'Attach to Order'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'attachToInvoice',
					label: this.FormMSG(6, 'Attach to Invoice'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'actions',
					label: this.FormMSG(75, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		caption1() {
			return this.FormMSG(1, 'Documents');
		}
	},

	methods: {
		viewDocument(item) {
			/*const urlStr = process.env.VUE_APP_GQL + '/images/' + item.xid
      window.open(urlStr) // in a new window
      return*/
			const list = this.Docs.map((doc) => ({
				...doc,
				src: `${process.env.VUE_APP_GQL}/images/${doc.xid}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + doc.xid,
				ext: getFileExtension(doc.fileName)
			}));
			const index = list.findIndex((l) => l.id === item.id);

			this.$previewImages({
				name: 'green_film_doc_previewer',
				images: list,
				focusIndex: index,
				options: {
					presentationMode: 'doc',
					hideCommentButton: false,
					hideDisLikeButton: false,
					hideLikeDislike: false,
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},

		async reloadData(id = null) {
			//console.log("reload data this.GFSubType:",this.GFSubType);
			const greenFilmId = isNil(id) ? parseInt(this.GFId, 10) : parseInt(id, 10);
			const parentType = this.GFType;
			const parentSubType = this.GFSubType;
			await getDocuments(greenFilmId, parentType, parentSubType)
				.then(async (records) => {
					// console.log("records:",records);
					this.Docs = records;
					for (let i = 0; i < this.Docs.length; i++) {
						this.$set(this.Docs[i], 'fullName', this.Docs[i].publisher.name + ' ' + this.Docs[i].publisher.firstName);
						if (this.attachmentToOfferOrderInvoiceOnly) {
							await getAttachStatus(this.Docs[i].xid)
								.then((record) => {
									this.$set(this.Docs[i], 'attachToOffer', record.attachToOffer);
									this.$set(this.Docs[i], 'attachToOrder', record.attachToOrder);
									this.$set(this.Docs[i], 'attachToInvoice', record.attachToInvoice);
								})
								.catch((error) => {
									//console.log(error)
									this.erreur = error;
								});
						}
					}
					// console.log("docs:", this.Docs);
				})
				.catch((error) => {
					//console.log(error)
					this.erreur = error;
				});

			return this.Docs.length;
		},

		removeDocument(xid) {
			// console.log('removing document :', xid)

			let action = () => {
				let retStr = '';
				let formData = new FormData(); // instantiate it
				formData.append('fileName', xid);
				const config = {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				};
				const urlStr = process.env.VUE_APP_GQL + '/' + this.removeUrl;
				axios
					.post(urlStr, formData, config)
					.then(async (response) => {
						retStr = response.data;
						//console.log("document removed:", retStr);
						this.isLoading = false;
						await this.reloadData();
						this.createToastForMobile(this.FormMSG(3, 'Success'), this.FormMSG(2, 'Element removed successfully'), '', 'success');
						this.$emit('image-child:removed', {
							parentId: this.GFId,
							xid
						});
					})
					.catch((error) => {
						console.log(error);
					});
				return retStr;
			};
			this.confirmModal(action, this.FormMSG(58, 'Are you sure ?'));
		},

		submitDocFile(ev) {
			this.isLoading = true;
			let docFil;
			if (ev.type == 'drop') {
				docFil = ev.dataTransfer.files[0];
			} else if (ev.type == 'change') {
				docFil = ev.target.files[0];
			}
			if (isFileSupported(docFil, [this.acceptFiles])) {
				// console.log('submitDocFile this.GFSubType:', this.GFSubType)
				let formData = new FormData();
				formData.append(this.formFilename, docFil);
				formData.append('parentType', this.GFType);
				formData.append('parentSubType', this.GFSubType);
				formData.append('parentId', this.GFId);
				formData.append('fileName', docFil.name);
				formData.append('fileType', docFil.type);
				const urlStr = process.env.VUE_APP_GQL + '/' + this.uploadUrl;
				//console.log("in submitdocfile, urlStr:", urlStr);
				//console.log("in submitdocfile, parentSubType:", this.GFSubType);
				axios
					.post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(async (result) => {
						const filename = result.data;
						const xid = filename.substring(0, filename.lastIndexOf('.'));
						// console.log({filename, xid});
						//"pdfFileId"
						this.$refs['pdfFileId'].reset();

						this.$emit('image-child:added', {
							parentId: this.GFId,
							xid
						});
						// save document with update
						await this.reloadData();
					})
					.catch((e) => {
						console.error({ e });
					})
					.finally(() => (this.isLoading = false));
			} else {
				this.createToastForMobile(
					this.FormMSG(123, 'Error'),
					docFil.name + ' : ' + this.FormMSG(124, 'The file format is not supported. Please upload a supported file.'),
					'',
					'danger'
				);
				this.$refs.pdfFileId.reset();
				this.isLoading = false;
			}
		},
		async handleAttachToOffer(payload, xid) {
			// console.log({payload, xid});
			await updateImageChild(xid, 0, payload).catch((e) => {
				console.error({ e });
			});
		},
		async handleAttachToOrder(payload, xid) {
			// console.log({payload, xid});
			await updateImageChild(xid, 1, payload).catch((e) => {
				console.error({ e });
			});
		},
		async handleAttachToInvoice(payload, xid) {
			// console.log({payload, xid});
			await updateImageChild(xid, 2, payload).catch((e) => {
				console.error({ e });
			});
		}
	},

	updated() {
		this.$emit('el:updated', this.Docs.length);
	}
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}
</style>
